import React from "react"

import { Layout } from "../../components/layout"
import { SEO } from "../../components/seo"

const Page = () => {
  return (
    <>
      <SEO title="Kontakt oss" />
      <Layout>
        <div className="relative bg-white">
          <div className="absolute inset-0">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-warm-grey-50"></div>
          </div>
          <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div className="bg-warm-grey-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <div className="max-w-lg mx-auto">
                <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-warm-grey-900 sm:text-3xl sm:leading-9">
                  Hör av dig
                </h2>
                <p className="mt-3 text-lg leading-6 text-warm-grey-500">
                  Hör gärna av dig till oss om du har några frågor eller
                  funderingar.
                </p>
                <dl className="mt-8 text-base leading-6 text-warm-grey-500">
                  <div>
                    <dt className="sr-only">Postal address</dt>
                    <dd>
                      <p>Förskolan Brandbilen</p>
                      <p>Älgvägen 19</p>
                      <p>89143 Örnsköldsvik</p>
                    </dd>
                  </div>
                  <div className="mt-6">
                    <dt className="sr-only">Telefon</dt>
                    <dd className="flex">
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-warm-grey-400"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                      </svg>
                      <span className="ml-3">0660-150 22</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-warm-grey-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="ml-3">
                        styrelsen@forskolanbrandbilen.se
                      </span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Rektor Linda Stenberg</dt>
                    <dd className="flex">
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-warm-grey-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <div className="flex flex-col">
                        <span className="ml-3">Rektor Linda Stenberg</span>
                        <span className="ml-3">
                          forskolechef@forskolanbrandbilen.se
                        </span>
                      </div>
                    </dd>
                  </div>
                </dl>
                {/* <p className="mt-6 text-base leading-6 text-warm-grey-500">
                  Looking for careers?
                  <a href="#" className="font-medium text-warm-grey-700 underline">View all job openings</a>.
                </p> */}
              </div>
            </div>
            <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div className="max-w-lg mx-auto lg:max-w-none">
                <form
                  data-netlify="true"
                  netlify-honeypot="contact_bot_field"
                  name="contact-general"
                  method="POST"
                  action="/kontakt/tack"
                  className="grid grid-cols-1 gap-y-6"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact-general"
                  />
                  <div className="hidden">
                    <label htmlFor="contact_bot_field">
                      Don’t fill this out if you're human
                    </label>
                    <input id="contact_bot_field" name="contact_bot_field" />
                  </div>
                  <div>
                    <label htmlFor="name" className="sr-only">
                      Namn
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        name="name"
                        id="name"
                        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                        placeholder="Namn"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      E-post
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                        placeholder="E-post"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="phone" className="sr-only">
                      Telefon
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        name="phone"
                        id="phone"
                        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                        placeholder="Telefon"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="message" className="sr-only">
                      Meddelande
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <textarea
                        name="message"
                        id="message"
                        rows={4}
                        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                        placeholder="Meddelande"
                      ></textarea>
                    </div>
                  </div>
                  <div className="">
                    <span className="inline-flex rounded-md shadow-sm">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:border-bb-red-700 focus:shadow-outline-bb-red active:bg-bb-red-700 transition duration-150 ease-in-out"
                      >
                        Skicka
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Page
